.main-header {
  .btn-full {
    width: 100%;
  }

  .btn-outline {
    border: solid 1px $primary;
  }

  .btn-border-white {
    border-color: $color-white;
  }

  .text-center {
    text-align: center;
  }

  .btn-apply {
    padding: 8px 12px;
  }

  form.search-bar {
    display: flex;
    justify-content: flex-end;
  }

  .btn-spend {
    font-family: 'proxima_nova_softmedium';
    color: $brand-blue;
    padding: 8px 16px;
    font-size: 14px;
    line-height: 140%;
    gap: 4px;
    display: flex;
    align-items: center;
    letter-spacing: -0.1px;
    background: $brand-light-blue;

    .available-spend-suffix {
      font-size: 10px;
    }

    @media (max-width: 992px) {
      border-radius: 0;
    }
  }

  .postcode-modal {
    position: absolute;
    top: 2.5rem;
    left: -5rem;
    width: 375px;
    min-height: 100%;
    max-width: 100%;
    z-index: 99;
    background: #0054a6;
    padding: 1rem;
    min-width: 375px;
    &:after {
      bottom: 100%;
      left: 50%;
      border: solid transparent;
      content: '';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(136, 183, 213, 0);
      border-bottom-color: $brand-blue;
      border-width: 16px;
      margin-left: -16px;
    }

    @media (max-width: 991px) {
      position: relative;
      top: 0;
      left: 0;

      &:after {
        display: none;
      }
    }

    img {
      width: 60px;
      height: 61px;
    }
  }
}

// Navbar

.sign-in-xl .nav-link i,
.sign-in-xl .nav-link span {
  color: $light-white;
}

.navbar-xs-search {
  position: relative;
  left: 20px;
}

@media (max-width: 991.98px) {
  .navbar {
    padding: 0;
  }

  .navbar-collapse {
    position: fixed;
    left: 0;
    top: 0;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    padding: 50px 20px;
    width: 20em;

    &.collapsing {
      right: 20.3em;
      display: block;
      z-index: 99;
      min-height: 100%;
      height: 100vh;
      background-color: $color-white;
      -webkit-transition: left 0.3s ease;
      -o-transition: left 0.3s ease;
      -moz-transition: left 0.3s ease;
      transition: left 0.3s ease;
    }

    &.show {
      left: 0;
      display: block;
      z-index: 99;
      height: 100vh;
      overflow: auto;
      background-color: $color-white;
      -webkit-transition: right 1s ease-in;
      -o-transition: right 1s ease-in;
      -moz-transition: right 1s ease-in;
      transition: right 1s ease-in;
      overflow: hidden;

      &:after {
        width: calc(100vw - 20em) !important;
        right: 0;
        left: auto !important;
      }
    }
  }

  .navbar-collapse .nav-item .nav-link span,
  .navbar-collapse .nav-item .nav-link i {
    color: $dark;
  }

  .nav-link span {
    color: $color-white;
  }
}

@media (min-width: 992px) {
  #navbarCollapse.navbar-collapse.show:after {
    display: none;
  }
}

// Mobile Header bars
@media (max-width: 991.99px) {
  .mobile-search-modal form.mb-0.bg-transparent.search-bar,
  .input-group-prepend-overlay {
    visibility: visible;
    margin: 3rem 2rem;
  }

  .mobile-search-modal form .input-group {
    display: flex !important;
  }

  .mobile-search-modal button.search-btn {
    display: none;
  }

  .rr-mobile-header {
    font-size: 14px;

    .mobile-account-credit {
      padding: 1rem;
      background: $brand-light-blue;
      // display: flex;
      // flex-direction: row;
      // justify-content: center;
      // align-items: center;
      // gap: 4px;

      .modal-content {
        background: $color-white;
      }

      .modal-header {
        border-bottom: none;
      }

      .btn-apply {
        padding: 2px 8px;
        border-radius: 60px;
        font-size: 12px;
      }
    }
  }
}

li.nav-item.dropdown a {
  font-size: 16px;
  color: $brand-navy;
}

.widget.widget-links.mb-0 {
  a {
    color: $brand-navy;
  }
}

.container.header {
  padding: 0.5em 0em;

  @media (max-width: 991.98px) {
    padding: 0;
  }
}

nav.navbar.navbar-expand-lg.py-0 {
  background: #f7f7f7;

  li.nav-item.dropdown {
    a.nav-link {
      color: #0e3460;
      font-weight: 400;
      font-family: 'proxima_nova_softregular';
    }
  }
}

.col-xl-auto.col-md-12.mb-3.mb-lg-0.order-xl-3 {
  @media (min-width: 1199.98px) {
    display: flex;
    justify-content: flex-end;
  }
}

@media (min-width: 992px) {
  nav {
    .navbar-nav .dropdown {
      position: relative;
    }

    .navbar-nav .dropdown-menu {
      left: -65px;
      min-width: 250px;
      top: 47px;
      width: auto;
      a:hover {
        color: $brand-navy !important; //override core
      }
    }
  }
}

// mobile menu
.mega-menu.dropdown-menu a:hover {
  color: $brand-navy !important;
}

a.btn.btn-apply:hover {
  color: #fff;
  background: #0ca855;
}

// Mobile search
@media (max-width: 992px) {
  .container-fluid.bg-lightgray.mobile-search-modal.position-fixed.w-100.h-100.top-0.start-0.z-index-2 {
    z-index: 999 !important;
  }

  .input-group.input-group-lg.border-0 {
    margin-right: 2em;
    padding-top: 1em;
  }

  li.navbar-account {
    align-items: center;
    display: flex;
  }
}

// sign in icon header
@media (max-width: 768px) {
  .sign-in {
    display: grid;
    grid-template-columns: 1fr 2fr;
  }
}

i.bi.bi-person-fill.fs-3 {
  justify-content: flex-end;
  display: flex;
  padding-right: 5px;
}

.sign-in {
  width: max-content;
  display: flex;

  span {
    color: $brand-navy;
  }

  i {
    color: $brand-navy;
  }
}

.navbar-collapse > ul {
  color: $brand-navy;

  li .bi-chevron-right {
    font-size: 10px;
    color: $brand-navy;
    -webkit-text-stroke: 1px $brand-navy;
  }

  a.nav-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.signin-mobile {
  margin-left: -1.5em;
  margin-right: -1.5em;
}

.navbar-x-button {
  &:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
  }

  span {
    -webkit-text-stroke: 1px;
    margin-right: 0.6em;
  }

  @media screen and (min-width: 992px) {
    span {
      display: none;
    }
  }
}

.custom-nav-items {
  a.nav-link {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 992px) {
  span.d-block.sign-in-text {
    color: #fff;
  }
}
@media screen and (max-width: 991.99px) {
  span.d-block.sign-in-text {
    color: $brand-navy !important;
    font-weight: bold;
  }
  i.bi.bi-person-fill.me-2.d-block.d-md-none {
    color: $brand-navy;
  }
}

a.d-flex.flex-row-reverse.justify-content-end.p-2.px-0.brand-navy {
  font-weight: normal;
  font-family: 'proxima_nova_softregular';
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

//Search
.search-text-span:hover {
  white-space: unset;
  background-color: #c6ccc8;
  border-radius: 5px;
  width: 100%;
  color: black;
  transition: 0.4s;
}

.search-text-span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  width: 100%;
  transition: 0.5s;
}

.predictive-search-container {
  position: absolute;
  background-color: white;
  border-radius: 0 0 5px 5px;
  margin-left: 1px !important;
  z-index: 10;
  top: 2.2rem;

  @media screen and (max-width: 992px) {
    top: 3rem !important;
  }
}

.search-mobile-results-container {
  margin: 0rem 0rem;
}

.category-product-divider {
  height: 0.5px !important;
}

.search-card-image {
  margin: 1em 0em;
  height: 5rem;
  width: 5rem;
}

.search-product-name {
  font-size: 16px;
  text-overflow: ellipsis;
}

// Mobile menu - Account
a.nav-link.d-flex.justify-content-between.p-2 {
  font-family: 'proxima_nova_softregular';
  font-weight: 400;
  font-size: 16px;
}
@media (max-width: 767px) {
  .search-bar .input-group.input-group-lg.border-0 {
    margin-right: 0 !important;
    border: 1px solid #ced4da !important;
    padding-top: 0;
  }
  .search-bar .input-group-lg > .form-control {
    padding: 0.5rem 1rem 0.5rem 0;
  }
  .mobile-search-modal form.mb-0.bg-transparent.search-bar {
    margin: 3rem 0rem;
  }
  .search-product-name {
    font-size:13px;
  }
}

.nav-categories{
  .dropdown-toggle:after{
    display: none;
  }
  li.nav-item:last-child .mega-menu {
    left: -200px;
  }
  li.nav-item:first-child .mega-menu {
    left: 0;
  }
  ul li:first-child{
    display: none;
  }
}
