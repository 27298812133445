// Shopping cart - cart line item
.card-body.py-0 {
    .row.align-items-center.py-3 {
        a.link {
            text-decoration: none;
        }
    }
}

.col-12.cart-apply-now {
    button.btn.btn-apply {
        width: 100%;
    }
}

// Promo code

.input-group.input-group-lg.bg-light.border-none.promo-code {
    border: none;
    justify-content: space-between;
    button.btn.btn-link {
        font-size: 16px;
        text-decoration: none;
        font-weight: 600;
        color: $light-gray
    }
}
.card.mb-4.border-none.promo-card {
    border: none;
}

// Apply now button
.row.px-0.cart-buttons {
    .col-12.cart-apply-now {
        .d-block.d-md-none {
            display: none !important;
        }
    }
}

// Cart summary

.col-lg-4.col-md-12.px-4 {
    .subtotal {
        font-size: 20px;
    }
    .total {
        font-weight: bold;
        font-size: 20px;
    }
    .cart-per-Week {
        color: #929292;
        font-size: 16px;
    }
    .postcode-included {
        color: #929292
    }
    .card.mb-4.border-none {
        border: none;   
    }
}

//Responsive cart layout
a.link.brand-navy {
    font-size: 16px;
    text-decoration: none;
    font-weight: 400;
}
@media (max-width: 480px) {
    .col-6.justify-content-center.cart-price {
    padding-bottom: 1em;
}
    .product-price {
        color: $brand-navy;
    }
    .cart-frequency {
        font-size: 14px;
    }
}

// Mini Cart
nav .nav-item i.cart-close {
    font-size: 28px;
  }
  
  .cart-remove {
    text-decoration: none;
    font-size: 13px;
    i {
      margin-top: 2px;
    }
  }
  
  li.nav-item.dropdown a.cart-product-name {
    color: $brand-navy;
    font-size: 16px;
  }
  
  .cart-frequency,
  .cart-term,
  .cart-due {
    font-weight: 400;
    font-size: 13px;
    color: $light-gray;
  }
  
  .cart-term {
    font-size: 11px;
  }
  
  .cart-right-col {
    width: 80px;
    min-width: 80px;
  }
  
  .cart-dropdown {
    position: static;
  }
  
  .mini-cart {
    width: 100%;
  }
  
  @include media-breakpoint-up(sm) {
    .cart-dropdown {
      position: relative;
    }
    
    .mini-cart {
      width: 420px;
    }
  }