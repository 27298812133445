footer {
    background: #0d50a0 !important;
}

.copyrightText {
    color: #F7F7F7;
    font-size: 14px;
    font-family: 'proxima_nova_softregular';
    line-height: 20px;
    text-align: left;
    font-weight: 400;
    letter-spacing: -0.1px;
}

.footerColumns a {
    font-size: 16px;
    font-family: 'proxima_nova_softsemibold';
    text-align: left;
    color: #ffffff;
}
@media only screen and (max-width: 990px) {
    .footerColumns a {
        font-size: 14px;
    }
}
.footerColumns a:hover {
    color: #ffffff;
}

.footerColumns ul {
    margin-bottom: 0px !important;
}

.footerColumnOneMobile ul {
    columns: 2;
    -webkit-columns: 2;
    border-bottom: 1px solid #657D99;
    -moz-columns: 2;
}

.footerColumnOneMobile li {
    padding: 5px 0;
    border-bottom: 1px solid #657D99;
    margin-right: 5px;
}

.footerColumnOtherMobile ul {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
}

.footerColumnOtherMobile li {
    padding: 5px 0;
    margin-right: 5px;
}

.divider {
    border-top: 1px solid white;
}

.col-md-2.px-0:first-of-type {
    font-family: 'proxima_nova_softsemibold' !important;
}

.col-md-2.px-0 a:first-of-type {
    text-transform: uppercase;
}
footer a:hover * {
    color: #ffffff;
}

footer a:hover {
    color: #ffffff;
}

.footerTCLink{
    width: max-content;
}
