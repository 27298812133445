section.section-bg-blue {
  background-color: $brand-light-blue;
}

  .category-block {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex: 1 1;
    @media only screen and (max-width: 990px) {
      height: auto;
    }
  }

  .category-block-image {
    display: flex;
    align-items: flex-end;
    
    @media only screen and (max-width: 990px) {
      height: 50px;
    }
  }
  .category-text.text-center {
    display: flex;
    align-items: flex-end;
    font-family: 'proxima_nova_softsemibold';
    font-size: 16px;
    letter-spacing: 0px;
    text-align: center;
    color: $brand-navy;
    @media only screen and (max-width: 990px) {
      font-size: 14px;
    }
  }
  .col-12.text-center.shop-button {
    display: flex;
    justify-content: center;
    gap: 1em;
  }
  .col-12.text-center {
    h2 {
      @media only screen and (max-width: 990px) {
        font-size: 24px;
      }
    }
  }

section.section-bg-blue{
    background-color: $brand-light-blue;
    padding: 1em 0em;
}