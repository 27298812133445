// Photo Detail Gallery

@media (max-width: 480px) {
  div#primaryProductImageRow {
    display: none;
  }
}

// Product Detail
h1.fw-bold.mb-3.mt-2 {
  font-family: 'proxima_nova_softsemibold';
  font-size: 36px;
  line-height: 50px;
  color: $brand-navy;

  @media (max-width: 480px) {
    font-size: 24px;
    line-height: 33px;
  }
}

@media (max-width: 767px) {
  .no-padding {
    padding: 0 !important;
  }

  .no-margin {
    margin: 0 !important;
  }
}

// Sku otions
.row.sku-options {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
  column-gap: 20px;
  padding: 0 0.8em;
}

// Postcode selector
.row.m-0.bg-light {
  button.btn-outline-primary {
    border-radius: 60px;
    border-color: #101828;
    color: #101828;
    font-size: 12px;
    border: 1px solid #000;
  }
}

.col-7.d-flex.justify-content-between.pt-3 {
  h5.text-primary.m-0.d-flex.align-items-center.p-0 {
    font-size: 16px;
    padding-top: 0.4em;
  }
}

// Brand Name
.col-sm-12.col-md-6.mb-6.mb-md-0 {
  a.link.text-decoration-none {
    color: #929292;
    font-size: 16px;
  }
}

// Edit button
.postcode.btn-outline-primary:hover {
  background: #ffffff;
}

// Own it outright
button.btn.btn-outline-primary.border-0.w-50.m-2.nohover:hover {
  color: #0054a6;
  background-color: #fdfdfd;
}

// Modal
.modal-header {
  border-bottom: 0;
}

// Wishlist icon
i.bi.bi-heart {
  font-size: 16px;
  color: #929292;
}

// Product grid 
@media (max-width: 768.99px) {
  .product-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    grid-gap: 0.5em;
  }

  .card-body {
    flex: 1 1 auto;
    padding: 1em 0em;
  }

  .card.p-3.pt-2.h-100 {
    padding: 0.7rem !important;
  }
  .buyItemHeading{
    font-size: 22px;
  }
  .buyItemcontent{
    font-size: 20px;
  }
}

#productDetailAccordion .accordion-button::after {
  background: none;
  content: '+';
  transform: none;
  margin-top: -13px;
  font-size: 32px;
}

#productDetailAccordion .accordion-button:not(.collapsed)::after {
  content: '─';
  transform: none;
  background-image: none;
  width: max-content;
  margin-right: 5px;
  margin-top: -1px;
  font-size: 20px;
}

// Disclaimer
@media (max-width: 480px) {
p.fw-bold.fs-18.disclaimer {
  font-size: 13px;
  margin-bottom: 0;
}
p.fw-bold.fs-20.mb-0.disclaimer {
  font-size: 14px;
}
}