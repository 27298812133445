// Featured section homepage

a.featured-selection-tab-link {
  color: $brand-navy;
  font-size: 18px !important;
  font-weight: 600;
  font-family: 'proxima_nova_softmedium';

  &.active {
    color: $brand-blue !important;
    background-color: $brand-light-blue !important;

  }
}

.product-grid-homepage {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  grid-gap: 2em 0em;

  @media screen and (min-width: 510px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
      
@media (max-width: 480px) {
  a#pills-profile-tab,  a#pills-featured-tab {
    font-size: 14px;
  }
}
  @media screen and (min-width: 576px) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }

  @media screen and (min-width: 1240px) {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }

}


a#pills-featured-tab {
  color: #0054a6;
  color: $brand-blue;
  font-size: 16px;
  font-weight: 600;
  font-family: 'proxima_nova_softmedium';
  }
  a#pills-profile-tab {
    color: $brand-navy;
    font-size: 16px;
    font-weight: 600;
    font-family: 'proxima_nova_softmedium';
}
@media (max-width: 420px) {
  a#pills-profile-tab,  a#pills-featured-tab{
    font-size: 14px !important;
  }
}
@media (max-width: 330px) {
  a#pills-profile-tab,  a#pills-featured-tab{
    font-size: 12px !important;
  }
}