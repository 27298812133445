
//Custom variable
@import '@ten24/slatwall-storefront-react-private/src/assets/components/variables.scss';
// Custom Colors
@import './custom/custom-colors';

//Bootstrap icon
@import 'bootstrap-icons/font/bootstrap-icons.css';

// Bootstrap-5
@import 'bootstrap/scss/bootstrap';

// for slick slider
@import 'slick-carousel/slick/slick.css';
@import 'slick-carousel/slick/slick-theme.css';

// Custom Styles

@import '@ten24/slatwall-storefront-react-private/src/assets/components.scss';

@import './custom/custom-client.scss';
