a.nav-link-inline.font-size-sm.link.d-flex.justify-content-end {
    font-size: 14px;
    text-decoration: none;
}

.row.my-2.sign-in-page p {
    text-align: right;
    font-size: 14px;

    a.link.mx-1 {
        font-size: 14px;
    }
}

// Apply now modal 
.modal-body {
    .container.py-4.my-4 {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .my-4 {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }

    .mb-5.bg-white.col-md-7.apply-now {
        width: 100%;
    }

    button.btn.text-navy.btn-full.btn-outline.brand-blue:hover {
        color: #0054A6;
    }

    button.btn.brand-blue.btn-full:hover {
        color: #0054A6;
    }

    a.btn.brand-blue.btn-full.w-100 {
        background: none;
        color: $brand-blue;
    }
}

// border on postcode input in apply modal
.rbt.border {
    border: none !important;
}

// Forgot Password - Create account 
.mb-5.bg-white.col-md-7.forgot-password,
.mb-5.bg-white.col-md-7.apply-now {
    hr.mt-4 {
        display: none;
    }

    a.link {
        font-size: 14px;
    }

    h2.h4.mb-1 {
        color: $brand-navy;
        text-align: center;
    }

    button.btn.btn-primary {
        width: 100%;
    }
}

.row.my-2.sign-in-page {
    .d-block.d-md-none {
        visibility: hidden;
    }

}

// account menu
@media (max-width: 480px) {
    .flex-column.align-items-start.mb-5.text-left {
        padding-left: 1.5em !important;
    }
}

.flex-column.align-items-start.p-2.mb-5.text-left {

    .nav-link,
    i {
        color: $brand-navy;
    }
}

// sign out text 
button.nav-link.btn-link.d-flex.flex-row-reverse.justify-content-end.px-0.border-0.bg-transparent {
    font-family: 'proxima_nova_softmedium';
}

@media (max-width: 768px) {
    .account-wrapper {
        display: flex;
        flex-direction: column;
    }

    .main-content {
        order: -1;
    }
}

@media (max-width: 575px) {
    .col-lg-8.col-md-8.col-sm-12.p-0.bg-blue.account-summary {
        height: 100vh;
    }
}

a.d-flex.justify-content-between.p-2.text-white:hover {
    color: #ffffff !important;
}

.order-history-wrapper {
    height: fit-content !important;
}

.order-history-accordion-button::after {
    background-size: 0.75rem;
    display: flex;
    background-position: center;
}
@media(max-width: 767px){
    .align-right-responsive .col-8{
        text-align: right;
    }
    h2.brand-navy{
       padding: 20px 0 5px;
    }
}