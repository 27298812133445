// Main image carsousel
.carousel-inner {
  background: none;
}

.carousel-indicators {
  top: 100%;
}

.content-slider {
  background-image: none;
  .carousel-item {
    .carousel-image img {
      opacity: 1;
      min-height: auto;
      height: inherit;
      background-size: contain;
      height: 100%;
    }
  }
}

.carousel-item > .carousel-image > img {
  min-height: auto;
  height: inherit;
  background-size: contain;
}

// Responsive heights

@media (min-width: 1200px) {
  .content-slider .carousel-image {
    height: 45vh;
    max-height: 500px;
  }
}
@media (max-width: 1199.98px) {
  .content-slider .carousel-image {
    height: 40vh;
  }
}
@media (max-width: 991.98px) {
  .content-slider .carousel-image {
    height: 35vh;
  }
}
@media (max-width: 768px) {
  .content-slider .carousel-image {
    height: 30vh;
  }
}
// @media (max-width: 480px) {
//   .content-slider .carousel-image {
//     height: 20vh;
//   }
// }
@media (min-width: 992px) {
  .content-slider .carousel-item .carousel-image img {
    object-fit: cover;
    min-height: inherit;
    max-height: 500px;
  }
}
@media (min-width: 992px) {
  .content-slider .carousel-item {
    height: auto;
  }
}
@media (max-width: 767px) {
  .content-slider .carousel-indicators [data-bs-target] {
    width: 10px;
    height: 9px;
    border-radius: 50%;
  }
}
