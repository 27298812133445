.product-listing {
  .sort-options-button {
    button.btn.btn-secondary.dropdown-toggle {
      border: none;
      border-radius: 0;
      background: #f7f7f7;
      min-width: 10rem;
      margin:0.5rem;
    }
  }
  .dropdown-toggle::after {
    display: none;
  }
}

.sort-options-button {
  @media only screen and (max-width: 992px) {
    padding-bottom: 0em;
  }
}

.bi-sliders.rotate-90::before{
  -webkit-transform: rotate(-90deg) !important;
  -moz-transform: rotate(-90deg) !important;
  -ms-transform: rotate(-90deg) !important;
  -o-transform: rotate(-90deg) !important;
  transform: rotate(-90deg) !important;
  -webkit-text-stroke: 0.5px;
}

@media only screen and (max-width: 992px) {
  span.filter-text, span.sort-text {
  font-size: 14px;
  }
  h1.h5.mb-0.mt-4.fw-bold.font-accent.brand-blue {
    font-size: 24px;
}
}