
// Product page will move these style changes - added quickly for demo

.page-header.bg-light.p-4.text-center {
    background: none !important;
    padding: 0 1em !important;
    h1.display-6 {
        font-size: 28px;
    }
}

.d-flex.justify-content-end.sort-options {
    button.btn.btn-secondary.dropdown-toggle.text-white {
        color: #000 !important;
        border: 1px solid #000;

    }
}

.link:hover {
    -webkit-text-decoration-color: #000;
    text-decoration-color: #000;
    color: #0054A6;
}

.link-button:hover {
    color: #000;
}
.product-listing {
    .sort-options {
        top: 11px;
        right: 10px;
        button.btn.btn-secondary.dropdown-toggle.text-white {
            border-radius: 0;
        }
    }
}

button.btn.btn-primary.btn-block {
    width: 100%;
}
a.product-name.d-inline-block.w-100.mt-2.brand-navy {
    font-family: 'proxima_nova_softregular';
}
span.price, span.product-card-tagline {
font-size: 20px;
font-weight: 700;
color: $brand-navy;
}