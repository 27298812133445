section.section-shopping-radio-rentals {
  background-color: #f7f7f7;
  padding: 2em;

  h2 {
    color: $brand-blue;
  }

  .brand-blue {
    background-color: $brand-blue;
    color: $light-white;
  }

  .category-block {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1 1;
    height: 30vh;
  }

  .category-block-image {
    display: flex;
    align-items: flex-end;
    height: 6vh;
  }
  h6.category-text {
    display: flex;
    font-family: 'proxima_nova_softsemibold';
    font-size: 16px;
    line-height: 22px;
    text-align: left;
    color: $brand-navy;
  }
  h6.category-text-black {
    display: flex;
    font-family: 'proxima_nova_softsemibold';
    font-size: 16px;
    line-height: 22px;
    text-align: left;
    color: $brand-black;
  }
  .col-md-6.col-12.text-center.shop-button {
    display: flex;
    justify-content: center;
    .btn {
      width: 18rem;
    }
  }
  .row.justify-content-sm-start {
    justify-content: space-around !important;
  }

  .col-12.text-center {
    h2.mobile-title {
      font-size: 24px;
    }
    h2 {
      @media only screen and (max-width: 990px) {
        font-size: 20px;
      }
    }
  }
  i.bi-check-circle-fill.d-block.d-sm-none {
    color: $brand-blue;
    padding-top: 2.5px;
    padding-right: 10px;
    font-size: 26px;
  }
}

.shopping-rental-product-page {
  .shopping-rental-product-page-title {
    display: none;
  }
  .shopping-rental-product-page-description {
    display: none;
  }
  .shopping-rental-product-page-button {
    display: none;
  }
}

.radio-rentals-accordion {
  .accordion-button {
    align-items: center;
    justify-content: center;
  }
}
