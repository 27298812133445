// TO DO - add the font Proxima Nova Soft

@font-face {
  font-family: 'proxima_nova_softbold';
  src: url('../../assets/fonts/proxima-nova-soft-bold-webfont.woff2') format('woff2'), url('../../assets/fonts/proxima-nova-soft-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'proxima_nova_softmedium';
  src: url('../../assets/fonts/proxima-nova-soft-medium-webfont.woff2') format('woff2'), url('../../assets/fonts/proxima-nova-soft-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'proxima_nova_softregular';
  src: url('../../assets/fonts/proxima-nova-soft-regular-webfont.woff2') format('woff2'), url('../../assets/fonts/proxima-nova-soft-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'proxima_nova_softsemibold';
  src: url('../../assets/fonts/proxima-nova-soft-semibold-webfont.woff2') format('woff2'), url('../../assets/fonts/proxima-nova-soft-semibold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body,
html {
  font-family: 'proxima_nova_softregular';
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'proxima_nova_softsemibold';
}

h3 {
  font-size: 23px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 15px;
}

h6 {
  font-size: 10px;
}

.nav-pills .nav-link {
  font-size: 20px;
}

.text-right {
  text-align: right;
}

.font-weight-bold {
  font-family: 'proxima_nova_softbold';
}

.font-weight-medium {
  font-family: 'proxima_nova_softmedium';
}

.font-weight-normal {
  font-family: 'proxima_nova_regular';
}

.font-weight-semibold {
  font-family: 'proxima_nova_softsemibold';
}

.container-custom-sm {
  max-width: 540px;
}

.container-custom-md {
  max-width: 720px;
}

.container-custom-lg {
  max-width: 960px;
}

.container-custom-xl {
  max-width: 1140px;
}

.lead {
  font-size: 16px;
}

.page-title {
  color: $brand-navy;
  font-size: calc(1.1em + 1vmin);
}

.call-out,
.call-out a {
  font-size: 13px;
}

.call-out a {
  font-family: 'proxima_nova_softbold';
}

.full-screen-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
  z-index: 9999; /* make sure it's above other elements */
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

.loading-text {
  margin-top: 20px;
  color: #ffffff; /* White text color */
  font-size: 20px;
  text-align: center;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.search-mobile-results-container {
  min-width: 500px;
}

.checkbox-callout {
  @extend .bg-lightgray;
  @extend .rounded;
  padding: 10px 20px 10px 40px;
  margin-bottom: 1.5em;
  font-size: 15px;

  a {
    font-size: 15px;
    color: $brand-blue;
    font-family: 'proxima_nova_softbold';
  }
}

.image-block a {
  width: 20%;
  display: inline-block;
  margin-right: 25px;
}

.image-block a img {
  width: 100%;
  height: auto;
  max-width: 228px;
}

.embed-container {
  position: relative;
  height: 100%;
  overflow: hidden;
  max-width: 100%;
}

.internal-title {
  color: $brand-blue;
  text-transform: uppercase;
  font-family: 'proxima_nova_softsemibold';
  letter-spacing: 1px;
  font-size: calc(1.1em + 1vmin);
}

h1.display-6 {
  font-family: 'proxima_nova_softsemibold';
  font-size: calc(1.1em + 1vmin);
  line-height: 140%;
  letter-spacing: -0.4px;
  color: $brand-navy;
}

.internal-title {
  color: $brand-blue;
  text-transform: uppercase;
  font-family: 'proxima_nova_softsemibold';
  letter-spacing: 1px;
  font-size: calc(1.1em + 1vmin);
}

.nav-link:hover {
  color: $brand-navy;
}

.multi-mega-menu:hover .mega-menu {
  display: block;
  margin-top: -7px;
}

.mega-menu:hover {
  display: block;
}

.nav-link-style:hover {
  color: $brand-navy;
}

.light-gray-text {
  color: $light-gray;
}

a:hover * {
  color: unset;
}

.bg-brand-blue {
  background-color: $brand-blue !important;
}

.bg-lightblue {
  background-color: $brand-light-blue;
}

.btn.btn-block {
  width: 100%;
}

input:disabled {
  cursor: default;
  background-color: #ffffff;
  color: #000000;
  border-color: #ffffff;
}

.btn.btn-light-blue {
  color: $brand-blue;
  background: $brand-light-blue;
  border: 1px solid $brand-light-blue;
  box-shadow: $btn-box-shadow;
  font-size: 16px;
}

.navbar-nav .nav-link {
  color: #000;
}

span.nav-link.text-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.row.justify-content-center.justify-content-lg-between.align-items-center.m-1 {
  min-height: 7rem;
}

.bg-transparent {
  background: transparent;
}

.bg-blue {
  background: $brand-blue;
}

.bg-navy {
  background: $brand-navy;
}

.text-navy {
  color: $brand-navy;
}

.text-red {
  color: $brand-red;
}

.bg-red {
  background: $brand-red;
}

.text-primary {
  color: $brand-blue;
}

.light-blue {
  color: #101828;
  background-color: $light-blue-btn;
}

.alert-info {
  color: #000;
  background-color: $brand-light-blue;
  border-color: $brand-light-blue;
}

// Header
nav.main-header.my-3.no-print.mt-0.mb-0.navbar-expand-md {
  background: $brand-blue;
}

// Main image carsousel
.content-slider .carousel-inner {
  background: none;
}

.content-slider .carousel-item .carousel-image img {
  opacity: 1;
}

// Top navigation icons
ul.nav.justify-content-center.justify-content-lg-end {
  span.nav-link.text-center {
    i.bi.bi-house-door.fs-4,
    i.bi.bi-shop.fs-4,
    span.d-block {
      color: $light-white;
    }
  }
}

.sign-in-mobile .nav-item span.d-block,
.sign-in-mobile .nav-item span i {
  color: $dark;
}

.navbar-collapse .nav-item span.d-block .navbar-collapse .nav-item span i {
  color: $light-white;
}

// Form input
.input-group {
  border-radius: 4px;
}

// Buttons

.btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: $border-radius;
  font-family: 'proxima_nova_softsemibold';
}

.btn-pb-1 {
  padding: 0.5rem;
}

.btn.btn-large {
  padding: $large-button-padding;
}

.btn.btn-small {
  padding: $small-button-padding;
}

.btn.btn-apply {
  background: $action-apply;
  color: $primary-text;
}

.btn-outline {
  border: solid $primary 1px;
}

.btn-outline.navy {
  border-color: $brand-navy;
}

.btn.btn-primary {
  color: $light-white;
  background: $brand-blue;
  border: $brand-blue;
  box-shadow: $btn-box-shadow;
  font-size: 16px;
}

.btn.btn-secondary,
.btn.btn-secondary:hover {
  color: $brand-blue;
  background: $light-white;
  border: $btn-secondary-border;
  box-shadow: $btn-box-shadow;
  font-size: 16px;
}

.bg-brand-navy {
  background: $brand-navy !important;
}

.brand-navy {
  color: $brand-navy !important;
}

.brand-blue {
  color: $brand-blue;
}

// Temp Overrides
.product-name:hover {
  color: #000;
}

.section-title h2:before,
.section-title .h2:before {
  display: none;
}

.section-title h2,
.section-title .h2 {
  margin-bottom: 1rem;
  color: $brand-blue;
}

.h-3 {
  height: 26px;
}

//Breadcrumb
ol.breadcrumb li a {
  color: $link-color;
  font-size: 14px;
}

// ol.breadcrumb li:last-child a {
//   color: $light-gray;
// }

.breadcrumb-item {
  font-family: 'proxima_nova_softmedium';
  font-size: 14px;
  line-height: 140%;
  text-align: center;
}

.breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  content: var(--bs-breadcrumb-divider, '>');
}

.breadcrumb-item:hover * {
  color: #000;
  -webkit-text-decoration-color: #000;
  text-decoration-color: #000;
}

// My Account
.my-account {
  padding: 1rem;
  border: 1px solid $brand-light-blue;
  border-radius: 4px;
}

.my-account .my-account-heading img {
  width: 25px;
  margin-right: 1rem;
}

.my-account .my-account-heading span {
  position: absolute;
  right: 60px;
}

.my-account .my-account-body div {
  margin-bottom: 1rem;
}

.my-account .my-account-body div p {
  font-size: 1rem;
}

//Accordion
.accordion-item {
  border: unset;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.accordion-button:focus {
  background-color: unset;
  border-color: unset;
  box-shadow: unset;
}

.accordion-button:not(.collapsed) {
  background-color: unset;
  border-color: unset;
  box-shadow: unset;
}

.accordion-button {
  color: $primary-blue;
}

.accordion .accordion-body {
  padding: 1rem 0;
}

.accordion .accordion-button {
  padding: 1rem 0;
}

.accordion-button:after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: '';
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 01.708 0L8 10.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

.accordion-button:not(.collapsed):after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23004c95'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 01.708 0L8 10.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z'/%3E%3C/svg%3E");
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.accordion-heading {
  font-family: 'proxima_nova_softsemibold';
  font-size: 20px;
  color: #0e3460;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.btn-wishlist {
  float: right;
  padding-top: 3px;
}

.recaptcha iframe {
  visibility: visible;
}

.complaints-form {
  position: relative;
  padding: 15px 21px 15px 15px;
  border: none;
  background-color: #dff1fa;
  margin-bottom: 21px;
  h2{
    font-size: 1.5rem;
    letter-spacing: 0;
    line-height: 27px;
    margin: 0 0 20px;
  }
}

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.fs-20 {
  font-size: 20px;
}

.fs-33 {
  font-size: 33px;
}

.fs-36 {
  font-size: 36px;
}

.zindex-1 {
  z-index: 1;
}

//postal code
.main-header .postcode-modal .col-6 {
  padding: 0 0.3rem;
}

.main-header .postcode-modal .col-6 button {
  padding-left: 0;
  padding-right: 0;
}

.custom-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: -1;
}

//postal code input
.container.px-4.no-padding {
  .rbt-input-main {
    background: transparent !important;
    border: 0 !important;
  }
}

// Mini Cart
nav .nav-item i.cart-close {
  font-size: 28px;
}

.cart-remove {
  text-decoration: none;
  font-size: 13px;

  i {
    margin-top: 2px;
  }
}

li.nav-item.dropdown a.cart-product-name {
  color: $brand-navy;
  font-size: 16px;
}

.cart-frequency,
.cart-term,
.cart-due {
  font-family: 'proxima_nova_softmedium';
  font-size: 13px;
  color: $light-gray;
}

.cart-term {
  font-size: 11px;
}

.cart-right-col {
  width: 80px;
  min-width: 80px;
}

.cart-dropdown {
  position: static;
}

.mini-cart {
  width: 100%;
}

@include media-breakpoint-up(sm) {
  .cart-dropdown {
    position: relative;
  }

  .mini-cart {
    width: 420px;
  }
}

.Toastify__toast-container--top-right {
  width: 100% !important;
}

.Toastify__toast-container .Toastify__toast p {
  margin: 0 !important;
}

.Toastify__toast-container .Toastify__toast {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 1024px !important;
  margin: 0 auto;
  max-width: 95% !important;
  margin-top: 1rem;
}

.Toastify__toast-container .Toastify__toast button.Toastify__close-button.Toastify__close-button--light {
  position: absolute;
  right: 10px;
}

.product-type-relative {
  position: static !important;
}

.product-type-relative .btn-group {
  right: 1rem;
  gap: 1em;
  @media (max-width: 768px) {
    position: relative;
    top: 0;
    right: 0;
  }
}

// Quantity Dropdown
.quantity-dropdown .custom-select {
  width: auto;
}

.primary-bg {
  background: #0e3460;
}

.btn-xs {
  padding: 0.25rem 1rem !important;
  font-size: 0.75rem;
}

.fill-red {
  filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(323deg) brightness(85%) contrast(200%);
}

// iframe {
//   visibility: hidden;
// }

.fw-bold {
  font-weight: 700 !important;
  font-family: 'proxima_nova_softbold';
}

.header-banner {
  padding: 8px 16px !important;
}

.successfull-application {
  max-width: 700px;
}

.alert-primary {
  background: #b3cce4;
}

label.login-checkbox {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  min-height: 24px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.login-checkbox__input {
  margin-right: 10px;
  font-size: 16px;
}

@media (max-width: 768px) {
  .login-page {
    .sign-in-page .btn.btn-apply {
      width: 100%;
    }

    button.btn.btn-apply {
      margin-top: 1em;
    }
  }

  nav.navbar-expand-md {
    position: sticky;
    top: 0;
    z-index: 9;
  }
  .search-mobile-results-container {
    min-width: 100%;
    max-width: 100%;
  }
}

.bg-light-blue-btn {
  background-color: $light-blue-btn;
}

.account-select-right-chevron {
  font-size: 12px;

  &::before {
    -webkit-text-stroke: 1px;
  }
}

.order-select-right-chevron {
  font-size: 10px;

  &::before {
    -webkit-text-stroke: 1px;
  }
}

.order-detail-table {
  tr {
    border: none !important;
  }
}

.order-status {
  color: $dark-gray-text;
  letter-spacing: 1px;
}

.top-banner-main {
  background: #e3f6ff;
  padding: 12px 0;
  position: relative;
  font-size: 14px;
  color: #595959;
}

li.top-banner_right {
  list-style: none;
  margin: 0 20px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.top-banner-main ul {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
}

.contact-info {
  background: #e3f6ff;
  border: 1px solid #d5d5d5;
  border-radius: 2px;
}

.row.justify-content-center .pr-4-lg {
  display: flex;
  flex-direction: column;
  background-color: rgb(4, 78, 163);
  background-position: left top;
  border-radius: 0px;
  width: calc(25% - 20px);
  margin: 10px;
  padding: 10px;
  align-self: stretch;
  img {
    width: -webkit-fill-available;
  }
}
footer .main-links ul li:last-child a {
  border-right: 0;
  padding-right: 0;
}

footer .main-links ul li a {
  padding-right: 0.5rem;
  text-transform: none;
  border-right: 2px solid #fff;
  font-size: 12px;
  font-family: 'proxima_nova_softsemibold';
}

.navbar-default {
  min-height: 34px;
}

.navbar-default a.nav-link {
  font-size: 12px;
  line-height: 1.4;
  padding: 0;
}

.rr-modal .modal-header,
.creditcorpgroup-modal .modal-header {
  // display: none;
  opacity: 0;
  position: absolute;
  right: 1rem;
  padding: 0;
  height: 4rem;
  width: 4rem;
  z-index: 99;
}

.rr-modal {
  .modal-body {
    background: url('../../assets/images/bg-popup-tablet.png') no-repeat center;
    width: 650px;
    height: 406px;
    padding: 100px 300px 30px 50px;
    margin-top: 0;
    margin-bottom: 0;
    background-size: cover;
  }
  .modal-dialog {
    max-width: 650px;
  }
}

.creditcorpgroup-modal {
  .modal-body {
    background: url('../../assets/images/bg-creditcorpgroup-popup-tablet.png') no-repeat center;
    width: 650px;
    height: 406px;
    padding: 100px 300px 30px 50px;
    margin-top: 0;
    margin-bottom: 0;
    background-size: cover;
  }
  .modal-dialog {
    max-width: 650px;
  }
}

@media only screen and (max-width: 767px) {
  .popup-form {
    max-width: 250px;
  }
  .contact-section {
    margin: 2rem;
  }

  .main-links {
    padding: 4rem 0 !important;
  }

  .terms-text {
    padding-top: 4rem !important;
    padding-bottom: 7rem !important;
  }

  .footer-copyright {
    font-size: 10pt !important;
    line-height: 14px !important;
  }

  .navbar-default {
    min-height: 42px;
    padding: 13px 10px 10px;
  }
  .navbar-brand img {
    height: 50px;
    width: auto;
    max-width: initial;
  }
  .row.justify-content-center .pr-4-lg {
    width: 100%;
    display: block;
  }
  .image-block a {
    width: 40%;
    display: inline-block;
    margin: 0.2rem;
  }
  li.top-banner_right {
    margin: 0 5px;
    font-size: 10px;
  }

  .rr-modal .modal-body {
    background: url('../../assets/images/bg-popup-mobile.png') no-repeat center;
    width: 350px !important;
    height: 500px !important;
    margin-top: 0;
    margin-bottom: 0;
    background-size: cover;
    padding-top: 20rem;
    height: auto;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 5rem;
    background-size: contain;
    background-position: center;
  }

  .creditcorpgroup-modal .modal-body {
    background: url('../../assets/images/bg-creditcorpgroup-mobile.png') no-repeat center;
    width: 350px !important;
    height: 500px !important;
    margin-top: 0;
    margin-bottom: 0;
    background-size: cover;
    padding-top: 20rem;
    height: auto;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 5rem;
    background-size: contain;
    background-position: center;
  }

  .popup-form h3 {
    font-size: 16px;
  }

  .popup-form p {
    font-size: 12px;
  }

  .popup-form input,
  .popup-form button {
    font-size: 14px;
    padding: 0.5rem 1rem !important;
  }

  .modal-dialog.modal-dialog-centered.modal-md {
    max-width: 350px;
    justify-content: center;
    margin: 0 auto;
    right: 1.5%;
  }

  .modal-content {
    background: transparent;
  }
}

@media (min-width: 768px) {
  .container-xl {
    max-width: 1170px;
    padding: 10px 20px 10px;
  }
}

button.disabled-spinner {
  i{
    display: none;
    margin-left: 10px;
  }
  &:disabled i{
    display: inline-block;
  }
}

// lastly load custom components
@import '../../components/Header/Header.module.scss';
@import '../../components/ShopCategory/ShopCategory.module.scss';
@import '../../components/ShopRadioRentals/ShopRadioRentals.module.scss';
@import '../../components/ProductCard/ProductCard.module.scss';
@import '../../components/Listing/ListingGrid.module.scss';
@import '../../components/Listing/ListingToolBar.module.scss';
@import '../../pages/Search/Search.module.scss';
@import '../../pages/Contact/Contact.scss';
@import '../../components/ContentSlider/ContentSlider.module.scss';
@import '../../components/ProductDetail/ProductDetail.module.scss';
@import '../../components/ProductTypeList/ProductTypeList.module.scss';
@import '../../pages/Cart/Cart.module.scss';
@import '../../components/Account/Account.module.scss';
@import '../../components/RelatedProductsSlider/RelatedProductSlider.module.scss';
@import '../../pages/SuccessfulApplication/SuccessfulApplication.module.scss';
@import '../../components/FeaturedSection/FeaturedSection.module.scss';
