.search-title-wrapper {
    gap: 0.5em;
}
@media only screen and (max-width: 480px) {
.search-row-wrapper.flex-row-reverse.d-flex {
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    }
    .btn-group.w-100 {
        gap: 0.5em;
    }
}
@media only screen and (min-width: 992px) {
    h1.h3.m-4.fw-bold.font-accent.brand-blue.fs-13 {
        margin-left: 0 !important;
        font-size: 24px;
        text-transform: none !important;
    }
  }
