// Product type scroller

button.btn.btn-light.m-2.text-nowrap {
  background: #e3f6ff;
  color: $brand-blue;
  font-family: 'proxima_nova_softsemibold';
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

::-webkit-scrollbar {
  height: 0;
}

.product-category-slick .slick-prev.slick-disabled:before,
.product-category-slick .slick-next.slick-disabled:before {
  opacity: 0;
}

.product-category-slick .slick-track {
  margin-left: 0;
}
