// Brand Colours
$brand-blue: #0d50a0; //#0e3460
$brand-navy:#0e3460;  //#0E3460;
$brand-red: #EE3350;
$brand-light-blue: #E3F6FF;
$action-apply: #0CA855;
$brand-black: #101828;
$primary: #0054A6;
$primary-text: #fff;
$link-color: #0054A6;
$primary-blue: #0054A6;
$color-white: #f7f7f7;
$color-black: #000000;
$fad-White: #ebebeb;
$gray-middle-level: #555;
$gray-top-level: #47494a;
$gray-text-input: #4b566b;
$gray-low-level: #b4aca8;
$mixed-white: #dae1e7;
$card-shadow: #eeeeee;
$dark: #212529;
$color-orange: #f78f1e;
$color-red: #ff0000;
$light-blue: #f2f8fb;
$light-white: #fff;
$color-gray: #f3f3f3;
$light-gray: #929292;
$dark-gray-text: #565656;

// Buttons
$btn-secondary-border: 1px solid #DCDCDC;
$btn-font-weight: 600;
$light-blue-btn: #71D7FD;

$border-radius: 4px;
$large-button-padding: 11px 16px;
$small-button-padding: 5px 10px;

$font-size: 16px;
$line-height: 1.4;
$lines-to-show: 4;

$step-count-size: 1.625rem !default;

$step-bar-height: 0.25rem !default;
$step-bar-border-radius: $step-bar-height / 2 !default;
$step-count-size: 1.625rem !default;
$step-count-font-size: 0.875rem !default;
$step-transition: color 0.25s ease-in-out !default;

$btn-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05), 0px 2px 1px rgba(0, 0, 0, 0.03), 0px 1px 1px rgba(0, 0, 0, 0.04);