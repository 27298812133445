.contact-info {
  .cancel-btn {
    margin-left: 20px;
  }
  .dis-flex {
    display: flex;
  }
  .contact-footer-contact {
    margin: 0;
    padding: 10px;
    text-align: center;
    border-radius: 3px;
  }
}
@media (max-width: 768.99px) {
  .cancel-btn {
    margin-left: unset !important;
    width: 100%;
    margin-top: 20px !important;
  }
  .custom-btn {
    width: 100%;
  }
  .dis-flex {
    display: unset !important;
  }
}
